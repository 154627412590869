import './App.css';
import { useEffect } from 'react';
import { ContextHolder, useAuth, useLoginWithRedirect, AdminPortal } from '@frontegg/react';

function App() {
  const { isAuthenticated } = useAuth();
  const loginWithRedirect = useLoginWithRedirect();

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect();
    } else {
      AdminPortal.show(); // Affiche le AdminPortal directement après l'authentification
    }
  }, [isAuthenticated, loginWithRedirect]);

  return (
    <div className='App'>
      {isAuthenticated ? (
        <div>Loading settings...</div> // Un message de chargement pendant que AdminPortal s'initialise
      ) : (
        <div>Redirecting to login...</div>
      )}
    </div>
  );
}

export default App;
