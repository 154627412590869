import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FronteggProvider } from '@frontegg/react';

//For Prod
const contextOptions = {
  baseUrl: "https://oauth.wealthwriteup.com",
  clientId: '6b45a1f3-3672-47ba-8763-99ad0cbefef3'
};

// Ajout de themeOptions pour le mode plein écran
const themeOptions = {
  adminPortal: {
    layout: {
      fullScreenMode: true
    }
  }
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <FronteggProvider contextOptions={contextOptions} themeOptions={themeOptions} hostedLoginBox={true}>
    <App />
  </FronteggProvider>
);

reportWebVitals();
